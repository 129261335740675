import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark, Box, Flex, Spacer, Button, Text
} from '@chakra-ui/react';
import { useRef, useEffect, useState } from 'react';




function RemoteStream() {
    const remoteVideoRef = useRef();
    const sharedAudioRef = useRef();

    const [audioCtx, setAudioCtx] = useState(null);
    const [remoteStream, setRemoteStream] = useState(null);

    const [remoteVolume, setRemoteVolume] = useState(0);
    const [audioContentVolume, setAudioContentVolume] = useState(0);

    const [remotePanner, setRemotePanner] = useState(0);

    const [remoteGainNode, setRemoteGainNode] = useState(null);
    const [audioContentGainNode, setAudioContentGainNode] = useState(null);

    const [splitterNode, setSplitterNode] = useState(null);
    const [panX, setPannerNode] = useState(null);
    const [mergerNode, setMergerNode] = useState(null);


    const [remotePannerNode, setRemotePannerNode] = useState(null);


    useEffect(() => {

        // console.log("Changing Volume to : ", audioContentVolume, " %")

        audioContentGainNode?.gain.setTargetAtTime(parseFloat(audioContentVolume * 0.01),
            audioCtx.currentTime, 0.01)

    }, [audioContentVolume])


    useEffect(() => {

        // console.log("Changing Volume to : ", audioContentVolume, " %")

        remoteGainNode?.gain.setTargetAtTime(parseFloat(remoteVolume * 0.01),
            audioCtx.currentTime, 0.01)

    }, [remoteVolume])


    useEffect(() => {

        // console.log("Pan Level: ", parseFloat((remotePanner / 100) * 2 - 1))

        remotePannerNode?.pan.setTargetAtTime(parseFloat((remotePanner / 100) * 2 - 1),
            audioCtx.currentTime, 0.01)

    }, [remotePanner])


    const initAudioContext = async () => {
        //  Create Audio Context Node
        // for cross browser compatibility
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const ac = new AudioContext();
        setAudioCtx(ac);

        console.log("Max channel count is :", ac.destination.maxChannelCount)
        console.log("Initialize Audio Context")

        // Create Media Stream Node for RemoteStream
        const rs = new MediaStream();
        setRemoteStream(rs);

        console.log("Initialize RemoteMedia Stream")
    }

    const initAudioNodes = async () => {

        const av = new GainNode(audioCtx)
        setAudioContentGainNode(av)
        console.log("Initialize AudioContent Gain Node")

        const rc = new GainNode(audioCtx)
        setRemoteGainNode(rc)
        console.log("Set Remote Gain Node");

        const rp = new StereoPannerNode(audioCtx)
        setRemotePannerNode(rp)
        console.log("Set Stereo Panner Node");

        await audioCtx.audioWorklet.addModule('./worklet/panX.js');
        const pn = new AudioWorkletNode(audioCtx, 'panX-processor', {
            channelCount: 6,
            channelCountMode: 'explicit',
            channelInterpretation: 'discrete',
        });
        setPannerNode(pn)
        console.log("Set Panner Node");

        const sn = new ChannelSplitterNode(audioCtx, { numberOfOutputs: 6 })
        setSplitterNode(sn)
        console.log("Set Splitter Node");

        const mn = new ChannelMergerNode(audioCtx, { numberOfInputs: 6 })
        setMergerNode(mn)
        console.log("Set Merger Node");
    }

    const onConnectAudioNode = async () => {
        if (audioCtx.state === 'suspended') {
            await audioCtx.resume()
        } 
     
        const audioPl = audioCtx.createMediaElementSource(sharedAudioRef.current)

        let pan = panX.parameters.get('gain');
        pan.setTargetAtTime(0.2, audioCtx.currentTime, .015);
    
        

    audioPl.connect(panX).connect(audioContentGainNode).connect(audioCtx.destination)

        console.log(" Connected Audioplayer with ", audioCtx.destination.channelCount, " channels")

        const webRTCStream = audioCtx.createMediaStreamSource(remoteStream);
        webRTCStream
            .connect(remoteGainNode)
            .connect(audioCtx.destination)
            // .connect(remotePannerNode)
            

            console.log("Connected All Gain nodes")

    }

    const getRemoteStream = async () => {

        console.log("Get Remote Stream")
        // Push tracks from local stream to peer connection
        window.localPC.getRemoteStreams()[0].getTracks().forEach(track => {
            console.log(track)
            remoteStream.addTrack(track);
        });

        remoteVideoRef.current.srcObject = remoteStream

    }

    const onChangeRemoteVolume = async (audiolevel) => {
        setRemoteVolume(audiolevel)
    }

    const onChangeAudioContentVolume = async (audiolevel) => {
        setAudioContentVolume(audiolevel)
    }

    const onChangeRemotePanner = async (panlevel) => {
        setRemotePanner(panlevel);
        if (panX) {
            let pan = panX.parameters.get('gain');
            pan.setTargetAtTime(panlevel / 100, audioCtx.currentTime, .015);
        }        
    }

    return (
        <Flex direction={'column'} height={'100vh'}>
            <Spacer />
            <audio
                controls
                ref={sharedAudioRef}
                // src={'/audio/lofi.mp3'}
                src={'/audio/toy-story-ch6.wav'}
            >
                Your browser does not support the
                <code>audio</code> element.
            </audio>

            <Slider
                aria-label='slider-ex-1'
                defaultValue={30}
                onChange={val => onChangeAudioContentVolume(val)}
            >
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>


            <Spacer />
            <Button margin={'auto'} onClick={() => initAudioContext()}>
                Initialise Audio Context
            </Button>
            <Spacer />

            <Box margin={'auto'}>
                <video ref={remoteVideoRef} width="480" height="240" autoPlay muted controls>

                </video>
            </Box>

            <Button margin={'auto'} onClick={() => getRemoteStream()}>
                Setup Remote Stream
            </Button>

            <Text> Remote Volume</Text>

            <Slider
                aria-label='slider-ex-1'
                defaultValue={30}
                onChange={val => onChangeRemoteVolume(val)}>
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>
            <Spacer />

            <Text>Remote Panner</Text>

            <Slider
                aria-label='slider-pan-1'
                defaultValue={50}
                onChange={val => onChangeRemotePanner(val)}
            >
                <SliderTrack>
                    <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
            </Slider>
            <Spacer />

            <Button margin={'auto'} onClick={() => initAudioNodes()}>
                Initialise all audio nodes
            </Button>
            <Button margin={'auto'} onClick={() => onConnectAudioNode()}>
                Connect all audio nodes
            </Button>
            <Spacer />
        </Flex>

    )
}


export default RemoteStream;