


function WavAudio() {


    return (
        <>
            <h1> Hello world</h1>

            <audio
                controls

                src={'/audio/toy-story-ch6.wav'}>
                Your browser does not support the
                <code>audio</code> element.
            </audio>

        </>

    )
}

export default WavAudio;
