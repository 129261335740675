import { useState } from "react";

function SharedContent() {


    return (
        <>
            Shared Content
        </>
    )
}

export default SharedContent;