import { Vstack, Box, Flex, Spacer, Button, TabList, Tab, Tabs, TabPanels, TabPanel, Input, Text } from '@chakra-ui/react';

import { firebaseApp } from '../firebase/clientApp';
import { getFirestore, collection, getDoc, doc, addDoc, setDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { useState } from 'react';

function CallControls() {
    const db = getFirestore(firebaseApp);


    const [answerMeetingID, setAnswerMeetingID] = useState('');
    const [offerMeetingID, setOfferMeetingID] = useState(null);
    const [boolAnswerTab, setBoolAnswerTab] = useState(false);
    const [boolOfferTab, setBoolOfferTab] = useState(false);

    const handleChange = (event) => setAnswerMeetingID(event.target.value)

    const onCreateOffer = async () => {

        // Create reference document ID for the new offer which will be stored in dB (firebase)
        const callDoc = doc(collection(db, "calls"));
        console.log("Offer Ref ID Created: ", callDoc.id)

        // Set Meeting ID variable
        setOfferMeetingID(callDoc.id) // Meeting ID

        // Subcollection reference for the Docmument ID created
        const offerCandidateReference = collection(db, "calls", callDoc.id, "offerCandidates");
        const answerCandidateReference = collection(db, "calls", callDoc.id, "answerCandidates");


        // Get ICE Candidates for caller and save it to firebase
        window.localPC.onicecandidate = async (event) => {
            if (event.candidate) {
                console.log('Offer IceCandidated Added', event.candidate.toJSON())
                // Save the ICE Candidates to the offerCandidates subcollection
                await addDoc(offerCandidateReference, event.candidate.toJSON())
            }
        }

        // Create offer
        const offerDescription = await window.localPC.createOffer();

        // Set the Local Description to RTCPeerConnection
        await window.localPC.setLocalDescription(offerDescription);
        console.log('Offer: Local Description Added')

        const offerSDP = {
            offer: {
                sdp: offerDescription.sdp,
                type: offerDescription.type,
            }
        }

        // Save the meeting info in firebase
        await setDoc(callDoc, offerSDP);

        // Listen for Remote Answer using onSnapShot and
        // save the Session Description to Remote Description
        onSnapshot(callDoc, (snapshot) => {
            const data = snapshot.data();
            if (!window.localPC.currentRemoteDescription && data?.answer) {

                const answerDescription = new RTCSessionDescription(data.answer);
                window.localPC.setRemoteDescription(answerDescription);
                console.log("Offer: Added Remote Description")
            }

        });


        // When Answered, Add Candidate to peer connection
        onSnapshot(answerCandidateReference, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type == 'added') {
                    const candidate = new RTCIceCandidate(change.doc.data());
                    window.localPC.addIceCandidate(candidate);

                    console.log("Offer: Answer ICE Candidate added");
                }
            })

        })



    }


    const onAnswerCall = async () => {

        // Create reference document ID for the new offer which will be stored in dB (firebase)
        const callDoc = doc(db, "calls", answerMeetingID);

        // Subcollection reference for the Docmument ID created
        const offerCandidateReference = collection(db, "calls", callDoc.id, "offerCandidates");
        const answerCandidateReference = collection(db, "calls", callDoc.id, "answerCandidates");

        console.log('Answer ID Reference', callDoc.id)

        // Get ICE Candidates for caller and save it to firebase
        window.localPC.onicecandidate = async (event) => {
            if (event.candidate) {
                console.log('Answer IceCandidate added fb', event.candidate.toJSON())
                // Save the ICE Candidates to the offerCandidates subcollection
                await addDoc(answerCandidateReference, event.candidate.toJSON())
            }
        }

        const callData = (await getDoc(callDoc)).data();

        const offerDescription = callData.offer;
        await window.localPC.setRemoteDescription(new RTCSessionDescription(offerDescription));
        console.log("Answer: Added Remote Description")

        const answerDescription = await window.localPC.createAnswer();
        await window.localPC.setLocalDescription(answerDescription);
        console.log("Answer: Added Local Description")


        const answerSDP = {
            answer: {
                sdp: answerDescription.sdp,
                type: answerDescription.type,
            }
        }

        // Update meeting info in firebase
        await updateDoc(callDoc, answerSDP);
        console.log("Answer Created and sent to firebase")


        // When Answered, Add Candidate to peer connection

        onSnapshot(offerCandidateReference, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type == 'added') {
                    const candidate = new RTCIceCandidate(change.doc.data());
                    window.localPC.addIceCandidate(candidate);
                    console.log("Answer: Offer ICE Candidate added");
                }
            })

        })


    }


    return (
        <Flex direction={'column'} height={'40vh'}>
            <Spacer />
            <Tabs isFitted variant='enclosed' bg={'white'}>
                <TabList mb='1em'>
                    <Tab isDisabled={boolOfferTab}>Create Offer</Tab>
                    <Tab isDisabled={boolAnswerTab}>Answer Call</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel h='200px'>
                        <Button onClick={() => onCreateOffer()}>
                            Create Offer
                        </Button>

                        {offerMeetingID &&
                            <Box>
                                <Text> Your Meeting ID: </Text>
                                <Text>{offerMeetingID}</Text>
                            </Box>

                        }
                    </TabPanel>
                    <TabPanel h='200px' >
                        <Text> Enter Meeting ID</Text>
                        <Input
                            value={answerMeetingID}
                            onChange={handleChange}
                            placeholder='Enter Meeting ID'
                        />
                        <Button onClick={() => onAnswerCall()}> Answer Call</Button>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <Spacer />

        </Flex >
    )
}

export default CallControls;