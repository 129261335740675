import { useEffect, useRef, useState } from "react";
import { Box, Button } from '@chakra-ui/react'


// const servers = {
//     iceServers: [
//         {
//             urls: ['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302'],
//         },
//     ],
//     iceCandidatePoolSize: 10,
// };

const servers = null;

const offerOptions = {
    offerToReceiveAudio: 1,
    offerToReceiveVideo: 1
};

let remoteStream = null;

function Test() {

    // States
    const [localStream, setLocalStream] = useState({});
    // const [remoteStream, setRemoteStream] = useState({});
    const [localPeerConn, setLocalPeerConn] = useState(null);
    const [remotePeerConn, setRemotePeerConn] = useState(null);
    const [muteStartButton, setMuteStartButton] = useState(false);
    const [muteCallButton, setMuteCallButton] = useState(true);
    const [muteHangButton, setMuteHangButton] = useState(true);
    const [audioConn, setAudioConn] = useState(null);

    const audioRemoteRef = useRef();

    // Hooks
    useEffect(() => {

        setAudioConn(new (window.AudioContext || window.webkitAudioContext)());
    }, [])

    function getName(pc) {
        return (pc === localPeerConn) ? 'Local Peer' : 'Remote Peer';
    }

    function getOtherPc(pc) {
        return (pc === localPeerConn) ? localPeerConn : remotePeerConn;
    }


    function onCreateSessionDescriptionError(error) {
        console.log(`Failed to create session description: ${error.toString()}`);
    }

    function onSetLocalSuccess(pc) {
        console.log(`${getName(pc)} setLocalDescription complete`);
    }

    function onSetRemoteSuccess(pc) {
        console.log(`${getName(pc)} setRemoteDescription complete`);

    }

    function onSetSessionDescriptionError(error) {
        console.log(`Failed to set session description: ${error.toString()}`);
    }

    function onAddIceCandidateSuccess(pc) {
        console.log(`${getName(pc)} addIceCandidate success`);
    }

    function onAddIceCandidateError(pc, error) {
        console.log(`${getName(pc)} failed to add ICE Candidate: ${error.toString()}`);
    }

    function onIceStateChange(pc, event) {
        if (pc) {
            console.log(`${getName(pc)} ICE state: ${pc.iceConnectionState}`);
            console.log('ICE state change event: ', event);
        }
    }

    function onIceCandidate(pc, event) {
        getOtherPc(pc)
            .addIceCandidate(event.candidate)
            .then(() => onAddIceCandidateSuccess(pc), err => onAddIceCandidateError(pc, err));
        console.log(`${getName(pc)} ICE candidate:\n${event.candidate ? event.candidate.candidate : '(null)'}`);
    }

    function onCreateOfferSuccess(desc) {
        console.log(`Offer from pc1\n${desc.sdp}`);
        console.log('localPeerConn setLocalDescription start');
        localPeerConn.setLocalDescription(desc).then(() => onSetLocalSuccess(localPeerConn), onSetSessionDescriptionError);
        console.log('remotePeerConn setRemoteDescription start');
        remotePeerConn.setRemoteDescription(desc).then(() => onSetRemoteSuccess(remotePeerConn), onSetSessionDescriptionError);
        console.log('remotePeerConn createAnswer start');
        remotePeerConn.createAnswer().then(onCreateAnswerSuccess, onCreateSessionDescriptionError);
    }


    function onCreateAnswerSuccess(desc) {
        console.log(`Answer from RemotePeerConn:\n${desc.sdp}`);
        console.log('RemotePeerConn setLocalDescription start');
        remotePeerConn.setLocalDescription(desc).then(() => onSetLocalSuccess(remotePeerConn), onSetSessionDescriptionError);
        console.log('LocalPeerConn setRemoteDescription start');
        localPeerConn.setRemoteDescription(desc).then(() => onSetRemoteSuccess(localPeerConn), onSetSessionDescriptionError);
    }

    function gotRemoteStream(e) {


        if (audioRemoteRef.current.srcObject !== e.streams[0]) {
            audioRemoteRef.current.srcObject = e.streams[0];
            console.log('Received remote stream');
        }

    }



    // Functions
    const onStartButton = async () => {
        console.log("Requesting Local stream")


        const ls = await navigator.mediaDevices
            .getUserMedia({
                audio: true
            })

        setLocalStream(ls)

        // setRemoteStream(new MediaStream());

        //  Create a Local Peer Connection 
        console.log("Created Local Peer Connection")
        setLocalPeerConn(new RTCPeerConnection(servers))

        // Create a Remote Peer Connection
        console.log("Created Remote Peer Connection")
        setRemotePeerConn(new RTCPeerConnection(servers))


        setMuteStartButton(true)
        setMuteCallButton(false)
        setMuteHangButton(false)
    }

    const onCallButton = async () => {

        localPeerConn.onicecandidate = e => onIceCandidate(localPeerConn, e);

        remotePeerConn.onicecandidate = e => onIceCandidate(remotePeerConn, e);

        localPeerConn.oniceconnectionstatechange = e => onIceStateChange(localPeerConn, e);
        remotePeerConn.oniceconnectionstatechange = e => onIceStateChange(remotePeerConn, e);

        remoteStream = new MediaStream();

        remotePeerConn.ontrack = gotRemoteStream;


        console.log('Added local stream to LocalPeerConn');
        localStream.getTracks().forEach(track => localPeerConn.addTrack(track, localStream));




        localPeerConn.createOffer(offerOptions).then(onCreateOfferSuccess, onCreateSessionDescriptionError);

    }


    const onHangButton = async () => {

        localPeerConn.close()
        remotePeerConn.close()

        setLocalStream(null)
        setLocalPeerConn(null)
        setRemotePeerConn(null)

        setMuteCallButton(true)
        setMuteStartButton(false)
        setMuteHangButton(true)

    }

    const onAudioButton = async () => {
        console.log(localStream)

        audioRemoteRef.current.srcObject = remoteStream

        // const webRTCStream = audioConn.createMediaStreamSource(remoteStream);

        // webRTCStream.connect(audioConn.destination)
        // console.log(audioConn)
        // audioConn.resume()
        // console.log('Audio context resumed playing')
        // audioRemoteRef.current.srcObject = webRTCStream.mediaStream;
        // audioRemoteRef.autoplay = true;


    }

    return (

        <Box>
            <Button
                onClick={() => onStartButton()}
                isDisabled={muteStartButton}
            >
                Start
            </Button>

            <Button
                onClick={() => onCallButton()}
                colorScheme='whatsapp'
                isDisabled={muteCallButton}
            >
                Call
            </Button>


            <Button
                onClick={() => onHangButton()}
                colorScheme='red'
                isDisabled={muteHangButton}
            >
                Hang up
            </Button>

            <audio ref={audioRemoteRef} controls autoPlay playsInline>

            </audio>


            <Button
                onClick={() => onAudioButton()}
                colorScheme='green'

            >
                get audio
            </Button>


        </Box >
    )
}

export default Test;