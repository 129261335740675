import { ChakraProvider } from '@chakra-ui/react';
import {
  HashRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import Header from "./components/header";
import Home from "./pages/index";
import Test from "./pages/test";
import WebAudio from "./pages/webaudio";

function App() {
  return (
    <ChakraProvider resetCSS={true}>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="test" element={<Test />} />
          <Route path="webaudio" element={<WebAudio />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
