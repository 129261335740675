import { Vstack, Box, Flex, Spacer, Button } from '@chakra-ui/react';
import { useRef, useEffect } from 'react';


const servers = {
    iceServers: [
        {
            urls: ['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302'],
        },
    ],
    iceCandidatePoolSize: 10,
};



let localStream = null;

const getWebcam = async (videoRef) => {

    const pc = new RTCPeerConnection(servers)
    console.log("Created Local RTC Peer Connection")

    window.localPC = pc;

    localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

    //Push tracks from local stream to peer connection
    localStream.getTracks().forEach((track) => {

        window.localPC.addTrack(track, localStream);
    });

    videoRef.current.srcObject = localStream
    console.log("Local Webcam Added to Video SRC Object")
}

function LocalStream() {
    const videoRef = useRef();

    useEffect(() => {

    }, [])

    return (
        <Flex direction={'column'} height={'100vh'}>
            <Spacer />

            <Box margin={'auto'}>
                <video ref={videoRef} width="480" height="240" autoPlay muted>

                </video>
            </Box>

            <Button margin={'auto'} onClick={() => getWebcam(videoRef)}>
                Start Webcam
            </Button>
            <Spacer />

        </Flex>

    )
}


export default LocalStream;