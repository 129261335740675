import { Box, Flex, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react'

function Header() {

    return (
        <Box>
            <Flex
                bg={useColorModeValue(' #990033', 'gray.800')}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.900')}
                align={'center'}>

                <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'center' }}>

                    <Text
                        textAlign={useBreakpointValue({ base: 'center', md: 'center' })}
                        fontFamily={'heading'}
                        color={useColorModeValue('white', 'white')}>
                        MetaRTC
                    </Text>
                </Flex>
            </Flex>


        </Box >
    )
}


export default Header;