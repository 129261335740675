import { Box, Flex, HStack, Vstack, Grid, GridItem, Text } from '@chakra-ui/react'

import LocalStream from '../components/localStream';
import RemoteStream from '../components/remoteStream';
import CallControls from '../components/callControls';
import SharedContent from '../components/sharedContent';

export default function Home() {
  return (
    <Grid
      templateRows='repeat(2, 1fr)'
      templateColumns='repeat(3, 1fr)'
      gap={4}
    >
      {/* Local Stream */}
      <GridItem rowSpan={2} colSpan={1} bg='papayawhip'>
        <LocalStream />
      </GridItem>

      {/* Shared Content Area */}
      <GridItem colSpan={1} bg='papayawhip'>
        <SharedContent />
      </GridItem>

      {/* Remote Stream */}
      <GridItem rowSpan={2} colSpan={1} bg='papayawhip'>

        <RemoteStream />
      </GridItem>

      {/* Call controls */}
      <GridItem colSpan={1} bg='papayawhip'>
        <CallControls />
      </GridItem>

    </Grid>
  )
}
